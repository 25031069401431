import React from "react";
import "./locaddress.css";

function LocAddress(props) {
  return (
    <div className="addHeadMain">
      <div>
        <h5 className="addHead">{props.item.location_address}</h5>
      </div>

      <div className="addList">
        <ul>
          {props.item.location_telephone ? (
            <li>
              <p className="addPara">
                <span className="addSub">T</span>
                <a href={`tel:+${props.item.location_telephone}`}>
                  {" "}
                  {props.item.location_telephone}
                </a>
              </p>
            </li>
          ) : (
            ""
          )}
          {props.item.location_fax ? (
            <li>
              <p className="addPara">
                <span className="addSub">F</span> {props.item.location_fax}
              </p>
            </li>
          ) : (
            ""
          )}
          <li>
            {props.item.location_hours_of_operation.map(value => (
              <p className="addPara">{value}</p>
            ))}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LocAddress;
