import React, { Component } from "react";
import "./menucard.css";
import "../CateringCard/cateringcard.css";
import Vegan from "../Vegan/Vegan";
import Gluten from "../Gluten/Gluten";
import SlideDown from "../SlideDown/SlideDown";
import ButtonLink from "../ButtonLink/ButtonLink";

import SmallCard from "../SmallCard/SmallCard";
import RecipeCardWrapper from "../RecipeCardWrapper/RecipeCardWrapper";
import RecipeCardWrapperHead from "../RecipeCardWrapperHead/RecipeCardWrapperHead";
import { ORDER_NOW } from "../../config/constants";
import { NavLink } from "react-router-dom";
var _ = require("lodash");

class Menucard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSection: ""
    };
  }
  openSection = title => {
    this.setState(
      (prevState, props) => {
        if (prevState.openSection === title) {
          return { openSection: "" };
        } else {
          return { openSection: title };
        }
      },
      () => {
        window.scrollTo(
          0,
          document.getElementById(_.camelCase(title)).offsetTop - 100
        );
      }
    );
  };

  render() {
    const {
      cyo,
      cheif_inspired,
      extra,
      button,
      header_image_mobile_menu,
      note
    } = this.props;
    const { openSection } = this.state;
    const CHEIF_INSPIRED = "Chef Inspired";
    const CYO = "Create your own";
    const EXTRA = "Extras";
    return (
      <div>
        <SmallCard heading="Menu" image={header_image_mobile_menu} />

        <div>
          <div className="paraDiv">
            <p className="menuPara">{note}</p>
          </div>

          <div className="logoDiv">
            <Vegan text="Vegan" /> <Gluten text="Gluten-Free" />
          </div>
        </div>
        <div className="dropDown">
          <SlideDown
            title={CYO}
            id={_.camelCase(CYO)}
            isOpen={this.state.openSection === CYO}
            handleOpenSection={() => this.openSection(CYO)}
          />

          <div
            title={CYO}
            style={openSection === CYO ? null : { display: "none" }}
          >
            <RecipeCardWrapper item={cyo.items} extra_class="newText" />

            <RecipeCardWrapperHead base_headers={cyo.base_headers[0]} />
            <RecipeCardWrapper item={cyo.base_items} />

            <RecipeCardWrapperHead base_headers={cyo.protein_headers[0]} />
            <RecipeCardWrapper item={cyo.protein_items} />

            <RecipeCardWrapperHead base_headers={cyo.topping_headers[0]} />
            <RecipeCardWrapper item={cyo.topping_items} />

            <RecipeCardWrapperHead base_headers={cyo.sauce_headers[0]} />
            <RecipeCardWrapper item={cyo.sauce_items} />
          </div>
        </div>

        <div className="dropDown">
          <SlideDown
            title={CHEIF_INSPIRED}
            id={_.camelCase(CHEIF_INSPIRED)}
            isOpen={this.state.openSection === CHEIF_INSPIRED}
            handleOpenSection={() => this.openSection(CHEIF_INSPIRED)}
          />

          <div
            title={CHEIF_INSPIRED}
            style={openSection === CHEIF_INSPIRED ? null : { display: "none" }}
          >
            <RecipeCardWrapper item={cheif_inspired.items} />
          </div>
        </div>

        <div className="dropDown">
          <SlideDown
            title={EXTRA}
            id={_.camelCase(EXTRA)}
            isOpen={this.state.openSection === EXTRA}
            handleOpenSection={() => this.openSection(EXTRA)}
          />

          <div
            title={EXTRA}
            style={openSection === EXTRA ? null : { display: "none" }}
          >
            <RecipeCardWrapperHead base_headers={extra.extra_headers[0]} />
            <RecipeCardWrapper item={extra.extra_items} />

            <RecipeCardWrapperHead base_headers={extra.drinks_headers[0]} />
            <RecipeCardWrapper item={extra.drinks_items} />
          </div>
        </div>

        {button.map(value => (
          <ButtonLink
            key={value.button_text}
            text={value.button_text}
            link={value.button_link_destination}
            targetLink="_blank"
          />
        ))}
        <div className="findOrd">
          <div className="buttonWidth">
            <div className="nearLoct">
              <NavLink exact to="/locations" className="nearLoctP btnText">
                Find Us
              </NavLink>
            </div>
          </div>
          <div className="buttonWidth">
            <ButtonLink text="Order Now" link={ORDER_NOW} targetLink="_blank" />
          </div>
        </div>
      </div>
    );
  }
}

export default Menucard;
