import React, { Component } from "react";
import {  Route, HashRouter } from "react-router-dom";
import Home from "../../pages/Home";
import Menu from "../../pages/Menu";
import Catering from "../../pages/Catering";
import Locations from "../../pages/Locations";
import Careers from "../../pages/Careers";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./route.css";
import { ROOT_URL } from "../../config/constants";

export default class Routes extends Component {
  render() {
    return (
      <React.Fragment>
        <HashRouter>
          <Header />
          <div className="body">
            <Route exact path={`${ROOT_URL}/`} component={Home} />
            <Route exact path={`${ROOT_URL}/menu`} component={Menu} />
            <Route exact path={`${ROOT_URL}/catering`} component={Catering} />
            <Route exact path={`${ROOT_URL}/locations`} component={Locations} />
            <Route exact path={`${ROOT_URL}/careers`} component={Careers} />
          </div>
          <Footer />
        </HashRouter>
      </React.Fragment>
    );
  }
}
