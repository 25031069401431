import React from 'react';
import "./socialicons.css";

function Socialicons (props) {
    const { icon, text } = props
    return (
        <div className="circle"><i className={`fa fa-${icon}`}></i>
            {text && (<span className="iconText">{text}</span>)}
        </div>
    );
}

export default Socialicons;