import React from 'react';
import "./gluten.css";
import glutenlogo from "../../assets/images/Fill-1.svg";

function Gluten (props) {
    return (
        <div className="glutenDisplay glutenMargin">
            <img alt="Gluten" src={glutenlogo} width="30" /><span className="glutenText">{props.text}</span>
        </div>
    )


}


export default Gluten;