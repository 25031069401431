import React, { Component } from "react";
import "./Locations.scss";
import LocationCard from "../../components/LocationCard/LocationCard";
import axios from 'axios';
import { BASE_URL, LOCATION_ID} from '../../config/constants';
import Loader from "../../components/Loader/Loader";
import MetaTags from 'react-meta-tags';
import ErrorMsg from '../../components/ErrorMsg/ErrorMsg';


export default class Locations extends Component {

  constructor(props) {
    super(props);
    this.state = {
        item : [],
        isLoaded: false,
        hasError: false,
        title:'',
        site_title:'',
        site_description:''
    }
    
  }
  componentDidMount(){
    axios.get(BASE_URL+LOCATION_ID)
    .then(res => 
      this.setState({
        item: res.data,
        title: res.data.seo.site_title,
        site_title: res.data.metadata._yoast_wpseo_title?res.data.metadata._yoast_wpseo_title:'Locations',
        site_description: res.data.metadata._yoast_wpseo_metadesc,
        isLoaded: true
      })
    )
    .catch(err => 
      this.setState({
        item: err,
        isLoaded: true,
        hasError: true
      })
    );


  }



  render() {
    const { hasError, item, isLoaded, site_title, title, site_description} = this.state;
    if(isLoaded)
    {
      if(hasError)
      {
        return <ErrorMsg />;
      }else{        
          return (
            <>
              <MetaTags>
              {site_title?
              <title>{` ${site_title} | ${title} `}</title>
              :
              <title>{`${title}`}</title>
              }
              <meta name="description" content={site_description} />
            
            </MetaTags>
            <LocationCard item={item} />
          </>
          );    

      }
    }else{
      return <Loader />
    }
  }
}
