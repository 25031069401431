import React, { Component } from "react";
import axios from "axios";
import "./Menu.scss";
import Menucard from "../../components/Menucard/Menucard";
import { BASE_URL, EXTRA_ID, CI_ID, CYO_ID } from "../../config/constants";
import Loader from "../../components/Loader/Loader";
import MetaTags from "react-meta-tags";
import ErrorMsg from "../../components/ErrorMsg/ErrorMsg";
export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      site_title: "",
      site_description: "",
      cyo: [],
      cheif_inspired: [],
      extra: [],
      isCyoLoaded: false,
      isCILoaded: false,
      isExtraLoaded: false,
      hasError: false,
      button: [],
      header_image_mobile_menu: "",
      note: ""
    };
  }
  componentDidMount() {
    axios
      .get(BASE_URL + CYO_ID)
      .then(res => {
        this.setState({
          header_image_mobile_menu:
            res.data.metadata.header_image_mobile_menu[0],
          note: res.data.metadata.create_your_own_note,
          button: res.data.menu_page.button,
          cyo: res.data.menu_page.cyo,
          isCyoLoaded: true,
          title: res.data.seo.site_title,
          site_title: res.data.metadata._yoast_wpseo_title
            ? res.data.metadata._yoast_wpseo_title
            : "Locations",
          site_description: res.data.metadata._yoast_wpseo_metadesc
        });
      })
      .catch(err =>
        this.setState({
          cyo: err,
          hasError: true,
          isCyoLoaded: true
        })
      );

    axios
      .get(BASE_URL + CI_ID)
      .then(res =>
        this.setState({
          cheif_inspired: res.data.menu_page.cheif_inspired,
          isCILoaded: true
        })
      )
      .catch(err =>
        this.setState({
          cheif_inspired: err,
          isCILoaded: true,
          hasError: true
        })
      );

    axios
      .get(BASE_URL + EXTRA_ID)
      .then(res =>
        this.setState({
          extra: res.data.menu_page.extra,
          isExtraLoaded: true
        })
      )
      .catch(err =>
        this.setState({
          extra: err,
          hasError: true,
          isExtraLoaded: true
        })
      );
  }

  render() {
    const {
      site_title,
      title,
      site_description,
      cyo,
      extra,
      cheif_inspired,
      isExtraLoaded,
      isCILoaded,
      isCyoLoaded,
      button,
      header_image_mobile_menu,
      note,
      hasError
    } = this.state;
    if (hasError) {
      return <ErrorMsg />;
    } else {
      if (isExtraLoaded && isCILoaded && isCyoLoaded) {
        return (
          <>
            <MetaTags>
              {site_title ? (
                <title>{` ${site_title} | ${title} `}</title>
              ) : (
                <title>{`${title}`}</title>
              )}
              <meta name="description" content={site_description} />
            </MetaTags>
            <Menucard
              note={note}
              cyo={cyo}
              extra={extra}
              cheif_inspired={cheif_inspired}
              button={button}
              header_image_mobile_menu={header_image_mobile_menu}
            />
          </>
        );
      } else {
        return <Loader />;
      }
    }
  }
}
