import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { slide as SlideMenu } from "react-burger-menu";
import Logo from "../Logo/Logo";
import "./sidemenu.css";
import { SOCIAL, ORDER_NOW } from "../../config/constants";

export default class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
    if(!this.state.menuOpen)
    {
      document.body.classList.add('overflowHidden');
    }else{
      document.body.classList.remove('overflowHidden');
    }
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  render() {
    return (
      <SlideMenu   isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
         <Logo logoStyle="logoSide" handleOpenSection={() => this.closeMenu()}/>
        <ul>
          <li>
            <NavLink
              onClick={() => this.closeMenu()}
              activeStyle={{ color: "#b7d037" }}
              exact
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => this.closeMenu()}
              activeStyle={{ color: "#b7d037" }}
              exact
              to="/menu"
            >
              Menu
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => this.closeMenu()}
              activeStyle={{ color: "#b7d037" }}
              exact
              to="/catering"
            >
              Catering
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => this.closeMenu()}
              activeStyle={{ color: "#b7d037" }}
              exact
              to="/locations"
            >
              Locations
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => this.closeMenu()}
              activeStyle={{ color: "#b7d037" }}
              exact
              to="/careers"
            >
              Careers
            </NavLink>
          </li>
        </ul>
        <a href={ORDER_NOW}>
          Order Now <i className="fa fa-arrow-right" />
        </a>
        <div className="socialIcon">
          <a target="_blank" rel="noopener noreferrer" href={SOCIAL.FACEBOOK}>
            {" "}
            <i className="fa fa-facebook" />{" "}
          </a>
          <a target="_blank" rel="noopener noreferrer" href={SOCIAL.INSTA}>
            {" "}
            <i className="fa fa-instagram" />{" "}
          </a>
          <a target="_blank" rel="noopener noreferrer" href={SOCIAL.TWITTER}>
            {" "}
            <i className="fa fa-twitter" />{" "}
          </a>
        </div>
      </SlideMenu>
      
    );
  }
}
