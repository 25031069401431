import React, { Component } from "react";
import "./Catering.scss";
import axios from "axios";
import {
  BASE_URL,
  PACKAGE_ID,
  GIB_ID,
  OPTION_ID
} from "../../config/constants";
import CateringCard from "../../components/CateringCard/CateringCard";
import Loader from "../../components/Loader/Loader";
import MetaTags from "react-meta-tags";
import ErrorMsg from "../../components/ErrorMsg/ErrorMsg";
export default class Catering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      package_data: [],
      genie_box: [],
      options: [],
      isPackageLoaded: false,
      isGenie_boxLoaded: false,
      isOptionLoaded: false,
      title: "",
      site_title: "",
      site_description: "",
      hasError: false,
      //button:[],
      header_image: "",
      header_image_mobile: "",
      extra: "",
      button: ""
    };
  }
  componentDidMount() {
    axios
      .get(BASE_URL + PACKAGE_ID)
      .then(res => {
        this.setState({
          header_image: res.data.metadata["Header Image"][0],
          header_image_mobile: res.data.metadata["Mobile Header Image"][0],
          title: res.data.seo.site_title,
          site_title: res.data.metadata._yoast_wpseo_title
            ? res.data.metadata._yoast_wpseo_title
            : "Catering",
          site_description: res.data.metadata._yoast_wpseo_metadesc,
          extra: res.data.catering_page,
          package_data: res.data,
          isPackageLoaded: true,
          button: res.data.seo.button
        });
      })
      .catch(err =>
        this.setState({
          package_data: err,
          hasError: true,
          isPackageLoaded: true
        })
      );

    axios
      .get(BASE_URL + GIB_ID)
      .then(res =>
        this.setState({
          genie_box: res.data,
          isGenie_boxLoaded: true
        })
      )
      .catch(err =>
        this.setState({
          genie_box: err,
          isGenie_boxLoaded: true,
          hasError: true
        })
      );

    axios
      .get(BASE_URL + OPTION_ID)
      .then(res =>
        this.setState({
          options: res.data,
          isOptionLoaded: true
        })
      )
      .catch(err =>
        this.setState({
          options: err,
          hasError: true,
          isOptionLoaded: true
        })
      );
  }
  render() {
    const {
      hasError,
      package_data,
      genie_box,
      button,
      options,
      header_image,
      header_image_mobile,
      extra,
      isPackageLoaded,
      isGenie_boxLoaded,
      isOptionLoaded,
      title,
      site_title,
      site_description
    } = this.state;
    if (hasError) {
      return <ErrorMsg />;
    } else {
      if (isPackageLoaded && isGenie_boxLoaded && isOptionLoaded) {
        return (
          <>
            <MetaTags>
              {site_title ? (
                <title>{` ${site_title} | ${title} `}</title>
              ) : (
                <title>{`${title}`}</title>
              )}
              <meta name="description" content={site_description} />
            </MetaTags>
            <CateringCard
              package_data={package_data}
              genie_box={genie_box}
              options={options}
              extra={extra}
              button={button}
              header_image={header_image}
              header_image_mobile={header_image_mobile}
            />
          </>
        );
      } else {
        return <Loader />;
      }
    }
  }
}
