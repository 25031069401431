import React from 'react';
import Dropdowntext from '../Dropdowntext/Dropdowntext';

const RecipeCardWrapperHead = (props) => {
    const { base_headers } = props;
   
    return (
        <div className="iconStyle iconStyleDot">
                <Dropdowntext 
                    item_title={base_headers.title}
                    item_description={base_headers.description}
                    />
          
        </div>
                
        
    )
}

export default RecipeCardWrapperHead;
