import React, { Component } from "react";
import "./cateringcard.css";
import SlideDown from "../SlideDown/SlideDown";
import ButtonLink from "../ButtonLink/ButtonLink";

import SmallCard from "../SmallCard/SmallCard";
import RenderHTML from "../RenderHTML/RenderHTML";
import Dropdowntext from "../Dropdowntext/Dropdowntext";
var _ = require("lodash");

class CateringCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSection: ""
    };
  }
  openSection = title => {
    this.setState(
      (prevState, props) => {
        if (prevState.openSection === title) {
          return { openSection: "" };
        } else {
          return { openSection: title };
        }
      },
      () => {
        window.scrollTo(
          0,
          document.getElementById(_.camelCase(title)).offsetTop - 100
        );
      }
    );
  };

  render() {
    const {
      package_data,
      genie_box,
      header_image,
      header_image_mobile,
      extra,
      button
    } = this.props;
    const { openSection } = this.state;
    const PACKAGES = "Packages";
    const GENIE_BOX = "Genie in a box";
    const OPTIONS = "Options";
    return (
      <div>
        <SmallCard heading="Catering" image={header_image_mobile} />

        <div>
          <div className="paraDiv">
            <p className="menuPara">
              To place a catering order call us at 312.913.3264 or email us at
              catering@idof.com
            </p>
          </div>
        </div>
        <div className="dropDown">
          <SlideDown
            title={PACKAGES}
            id={_.camelCase(PACKAGES)}
            isOpen={openSection === PACKAGES}
            handleOpenSection={() => this.openSection(PACKAGES)}
          />
          <div
            title={PACKAGES}
            className="menuMargin"
            style={openSection === PACKAGES ? null : { display: "none" }}
          >
            <p className="menuText">
              {" "}
              {package_data.metadata["Text before menu"][0]}{" "}
            </p>
            <p className="menuDescription">
              {" "}
              <RenderHTML item={package_data.content.rendered} />{" "}
            </p>
          </div>
        </div>

        <div className="dropDown">
          <SlideDown
            title={GENIE_BOX}
            id={_.camelCase(GENIE_BOX)}
            isOpen={this.state.openSection === GENIE_BOX}
            handleOpenSection={() => this.openSection(GENIE_BOX)}
          />
          <div
            title={GENIE_BOX}
            className="menuMargin"
            style={openSection === GENIE_BOX ? null : { display: "none" }}
          >
            <p className="menuText">
              {" "}
              {genie_box.metadata["Text before menu"][0]}{" "}
            </p>
            <p className="menuDescription">
              {" "}
              <RenderHTML item={genie_box.content.rendered} />{" "}
            </p>
          </div>
        </div>

        <div className="dropDown">
          <SlideDown
            title={OPTIONS}
            id={_.camelCase(OPTIONS)}
            isOpen={this.state.openSection === OPTIONS}
            handleOpenSection={() => this.openSection(OPTIONS)}
          />
          <div
            title={OPTIONS}
            className="menuMargin"
            style={openSection === OPTIONS ? null : { display: "none" }}
          >
            {extra.map(value => {
              return (
                <div className="optDiv" key={value.title}>
                  <div className="optHead">
                    {" "}
                    <Dropdowntext
                      item_title={value.title}
                      item_price={value.price}
                    />{" "}
                  </div>
                  <p className="optPara">
                    {" "}
                    <RenderHTML item={value.description} />{" "}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <ButtonLink
          link={button}
          targetLink="_blank"
          text="Download our catering menu"
        />
        <p className="afterText">
          {" "}
          <RenderHTML item={package_data.metadata["Text after menu"][0]} />{" "}
        </p>
      </div>
    );
  }
}

export default CateringCard;
