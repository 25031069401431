import React from 'react'
import './options.css'


function Options (props) {
   
    return (
        <>
        <span className={props.option_icon}>&nbsp;</span>   
        </>
    )
}

export default Options;
