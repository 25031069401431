import React from 'react';
import './error.css';
function ErrorMsg () {
    return (
        <div className="errorDiv">
            <i className="fa fa-exclamation-circle"></i>
            <p className="errorText">An Error Occured</p>
        </div>
    )
}

export default ErrorMsg;