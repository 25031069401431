import React from "react";
import "./recipecard.css";
import Foodicon from "../Foodicon/Foodicon";
import Dropdowntext from "../Dropdowntext/Dropdowntext";
// import Vegan from '../Vegan/Vegan';
// import Gluten from '../Gluten/Gluten';
// import Options from '../Options/Options';
// var _ = require('lodash');

const SmallCard = props => {
  const {
    image,
    item_title,
    item_description,
    item_price,
    item_options,
    extra_class
  } = props;

  return (
    <div className="iconStyle iconCss">
      {image ? <Foodicon image={image} /> : ""}
      <Dropdowntext
        item_title={item_title}
        item_description={item_description}
        item_price={item_price}
        item_options={item_options}
        extra_class={extra_class}
      />

      {/* <div className="mainVegan"> {_.includes(item_options,'Vegan')? <Vegan />:''} </div>
                            <div className="mainGluten">{_.includes(item_options,'Gluten Free')? <Gluten />:''} </div>  */}
    </div>
  );
};

export default SmallCard;
