import React from 'react';
import './loader.css';

function Loader () {
    return (
        <div className="loaderDiv">

        <div id="loading-wrapper">
        <div id="loading-content"></div>
        </div>

        </div>
    )
}

export default Loader;