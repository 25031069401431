import React from "react";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
function Logo(props) {
  return (
    
    <div className={props.logoStyle}  onClick={ props.handleOpenSection}>
      <NavLink exact to="/">
        <img alt="IDOF - Fresh Mediterranean" src={logo} width="80" />
      </NavLink>
    </div>
  );
}

export default Logo;
