import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { GOOGLE_API_KEY } from '../../config/constants';
import ICON_URL from "../../assets/images/black-icon.png";
const mapStyles = {};
export class MapLocation extends Component {
    render() {
        const {
            google,
            zoom,
            center,
            locations
        } = this.props;
    return (
        <div className="mapPic">
            <Map
            google={google}
            zoom={zoom}
            style={mapStyles}
            initialCenter={{ lat: center[0], lng: center[1]}}
            >
            {locations.map(value=> <Marker key={value[0]+value[1]} position={{ lat:value[0], lng: value[1]}} icon={{url:ICON_URL}} />)}
            </Map>
    </div>
  );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY
})(MapLocation);
