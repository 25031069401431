import React from "react";
import "./locationdetails.css";
import RenderHTML from "../RenderHTML/RenderHTML";

function LocationDetails(props) {
  return (
    <div className="loopDiv">
      <h6 className="loopStyle">
        <RenderHTML item={props.title} />
      </h6>
    </div>
  );
}

export default LocationDetails;
