import React from "react";
import "./dropdowntext.css";

import Options from "../Options/Options";
var _ = require("lodash");

const FOODICON = {
  Vegan: "icon_vsmall",
  "Gluten Free": "icon_gsmall",
  "Fire Icon": "icon_fire"
};

function Dropdowntext(props) {
  var item_options = _.values(props.item_options);
  console.log({ foodicon: item_options });
  return (
    <div className={`textDiv ${props.extra_class}`}>
      <h6 className="textHead">
        {props.item_title} <span>{props.item_price}</span>
        {item_options.map(opt => (
          <Options
            option_icon={_.isUndefined(FOODICON[opt]) ? "" : FOODICON[opt]}
          />
        ))}
      </h6>
      {props.item_description ? (
        <p className="textPara"> {props.item_description} </p>
      ) : (
        ""
      )}
    </div>
  );
}
export default Dropdowntext;
