module.exports = {
  GOOGLE_API_KEY: "AIzaSyAAVg33Dbz7jeAcPhFuTMc3BfziFnR1iXo", //'AIzaSyBjfp8aGyqPcGKpray_X10J-4wWiZMZSpM',
  //ICON_URL: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
  ORDER_NOW:
    "https://ordering.chownow.com/order/996/locations?add_cn_ordering_class=true",
  BASE_URL: "http://www.idreamoffalafel.com/wp-json/wp/v2/",
  ROOT_URL: "", // temporary
  // BASE_URL: 'http://localhost/idof/wp-json/wp/v2/',
  HOME_ID: "pages/564",
  EXTRA_ID: "pages/209",
  CYO_ID: "pages/205",
  CI_ID: "pages/207",
  PACKAGE_ID: "pages/15",
  GIB_ID: "pages/25",
  OPTION_ID: "pages/17",
  LOCATION_ID: "pages/90",
  // LOCATION_ID:'pages/86',
  CAREER_ID: "pages/459",
  SOCIAL: {
    FACEBOOK: "https://www.facebook.com/IDreamofFalafel/",
    INSTA: "https://www.instagram.com/idreamoffalafel",
    TWITTER: "https://twitter.com/iDreamofFalafel",
    EMAIL: "mailto:catering@idof.com",
    TOP: "#top"
  },
  PAGES: {
    "view menu": "menu",
    "catering menu": "catering",
    "our locations": "locations",
    careers: "careers"
  }
};
