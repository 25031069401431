import React from 'react';
import "./vegan.css";
import veganlogo from "../../assets/images/Vegan-Logo.svg";

function Vegan (props) {
    return (
        <div className="veganDisplay veganMargin">
            <img alt="Vegan" src={veganlogo} width="30" /><span className="veganText">{props.text}</span>
        </div>
    )


}


export default Vegan;