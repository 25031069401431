import React from 'react';
import './foodicon.css';

function Foodicon (props) {
    return (
        <div className="foodStyle">
           <img src={props.image} alt="" />
        </div>
    )
}

export default Foodicon;